<template>
	<div
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-register"
		>
		<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full bg-blue hidden lg:block">
			<div class="flex justify-center items-center h-full w-full">
				<enter-event-code />
			</div>
		</div>
		<div class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full m-4 lg:m-0">
			<div class="flex justify-center items-center h-full w-full">
				<div class="lg:p-10 max-w-lg lg:max-w-md mx-auto login-wrapper w-full">
					<img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
					<vx-card class="rounded-md">
						<div slot="no-body">
							<div class="vx-row no-gutter justify-center items-center">
								<div class="vx-col w-full">
									<div class="px-8 pt-8 login-tabs-container">
										<vs-row vs-type="flex" vs-justify="flex-start">
											<h4>{{ $t('Create account as host') }} </h4>
										</vs-row>

										<div class="mt-12">
											<register-jwt></register-jwt>
										</div>

                    <div class="pt-5 pb-5">
                      By signing up you agree with our <span class="text-blue cursor-pointer">terms & conditions</span>
                    </div>
									</div>
								</div>
							</div>
						</div>
					</vx-card>

					<div class="text-center mt-5">
						Already a user? <router-link to="/login">{{ $t('Login') }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EnterEventCode from '@/components/events/EnterEventCode.vue';
import RegisterJwt from './RegisterJWT.vue';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  components: {
    EnterEventCode,
    RegisterJwt,
  },
  methods: {
    loginUser() {
      this.$router.push('/login').catch(() => {});
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>

<style lang="scss">
.register-tabs-container {
	min-height: 517px;

	.con-tab {
		padding-bottom: 23px;
	}
}
.auth-card{
	min-width: 470px;
}
.auth-mobile{
	min-width: 300px;
}
</style>
