<template>
	<div>
    <!-- Email -->
		<vs-input v-filter-input-email
			v-validate="'required|email'"
			data-vv-validate-on="blur"
			name="email"
			type="email"
			:label-placeholder="$t('Email')"
			:placeholder="$t('Email')"
			v-model="email"
			class="w-full mt-10"
		/>
		<span class="text-danger text-sm">{{ errors.first('email') }}</span>

    <!-- Full Name -->
		<vs-input v-filter-input
			v-validate="'required|min:3'"
			data-vv-validate-on="blur"
			:label-placeholder="$t('Full Name')"
			name="fullName"
			:placeholder="$t('Full Name')"
			v-model="fullName"
			class="w-full mt-10"
		/>
		<span class="text-danger text-sm">{{ errors.first('fullName') }}</span>

    <!-- Company -->
    <vs-input v-filter-input
			v-validate="'min:3'"
			data-vv-validate-on="blur"
			:label-placeholder="$t('Company')"
			name="company"
			:placeholder="$t('Company')"
			v-model="company"
			class="w-full mt-10"
		/>
		<span class="text-danger text-sm">{{ errors.first('company') }}</span>

		<div class="vx-row" v-if="false">
			<div class="vx-col w-1/2">
			</div>
			<div class="vx-col w-1/2">
				<vs-input v-filter-input
					v-validate="'required|min:3'"
					data-vv-validate-on="blur"
					:label-placeholder="$t('Username')"
					name="username"
					:placeholder="$t('Username')"
					v-model="username"
					class="w-full mt-10"
				/>
				<span class="text-danger text-sm">{{ errors.first('username') }}</span>
			</div>
		</div>

		<div v-if="false">
			<VuePhoneNumberInput
				class="input-number mt-10"
				:default-country-code="'NO'"
				:preferred-countries="['NO', 'US', 'GB']"
				@update="onUpdate"
				v-model="phone"
			/>
			<span class="text-danger text-sm">{{ errors.first('phone') }}</span>
		</div>

    <!-- Password -->
		<div class="vx-row mt-2" v-if="false">
			<div class="vx-col w-1/2">
				<vs-input
					ref="password"
					type="password"
					data-vv-validate-on="blur"
					v-validate="'required|min:6'"
					name="password"
					:label-placeholder="$t('Password')"
					:placeholder="$t('Password')"
					v-model="password"
					class="w-full mt-10"
				/>
				<span class="text-danger text-sm">{{ errors.first('password') }}</span>
			</div>

			<div class="vx-col w-1/2">
				<vs-input
					type="password"
					v-validate="'min:6|confirmed:password'"
					data-vv-validate-on="blur"
					data-vv-as="password"
					name="confirm_password"
					:label-placeholder="$t('Confirm Password')"
					:placeholder="$t('Confirm Password')"
					v-model="confirm_password"
					class="w-full mt-10"
				/>
				<span class="text-danger text-sm">{{ errors.first('confirm_password') }}</span>
			</div>
		</div>

		<!-- is verification -->
		<div class="vx-row mt-6" v-if="false">
			<div class="vx-col sm:w-1/2">
				<h6 class="mb-4">{{$t('Verification Method')}}</h6>
			</div>
			<div class="vx-col sm:w-1/2">
				<vs-radio color="#4D94E0" v-model="dataVerificationMethod" vs-value='email'>{{ $t('Email') }}</vs-radio>
				<vs-radio class="ml-12" color="#4D94E0" v-model="dataVerificationMethod" vs-value='phone'>{{ $t('Phone') }}</vs-radio>
			</div>
		</div>

		<!-- <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6">I accept the terms & conditions.</vs-checkbox> -->
    <div class="flex justify-end">
      <vs-button class="mt-6 mb-4" @click="registerUserJWt" :disabled="!validateForm">{{$t('Sign Up')}}</vs-button>
    </div>
	</div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import authApi from '@/api/auth';
import { getAxiosErrorMessage } from '@/lib/helper';

export default {
  data() {
    return {
      // name: '',
      firstName: '',
      lastName: '',
      name: '',
      email: '',
      company: '',
      dataVerificationMethod: 'email',
      username: '',
      fullName: '',
      phone: '',
      lang: 'no',
      password: '',
      confirm_password: '',
      isTermsConditionAccepted: false,
      resultsPhone: {},

    };
  },
  components: {
    VuePhoneNumberInput,
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any()
				&& this.fullName !== ''
      );
    },
  },
  methods: {
    // international phone input number
    onUpdate(payload) {
      this.resultsPhone = payload;
    },
    resetForm() {
      this.fullName = '';
      this.email = '';
      this.username = '';
      this.password = '';
      this.confirm_password = '';
      this.phone = '';
      this.dataVerificationMethod = '';
    },
    checkLogin() {
      // If user is already logged in notify
      // if (this.$store.state.auth.isUserLoggedIn()) {
      if (this.$store.state.isLoggedIn) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;

      const params = {
        name: this.fullName,
        email: this.email,
        company: this.company,
        username: this.username,
        mobile_phone: this.resultsPhone.countryCallingCode + this.resultsPhone.nationalNumber,
        verification_method: this.dataVerificationMethod,
        lang: this.lang,
      };

      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: 'Register',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.resetForm();
      };

      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'Register',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      };

      authApi.register(params, callback, errorCallback);

      // const payload = {
      // 	userDetails: {
      // 		name: this.name,
      // 		email: this.email,
      // 		password: this.password,
      // 		confirmPassword: this.confirm_password,
      // 	},
      // 	notify: this.$vs.notify,
      // };
      // this.$store.dispatch('auth/registerUserJWT', payload);
    },
  },
};
</script>
